// Here you can add other styles
.form-control.is-invalid {
	background-image: none; // remove cross image
}

.cover {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}

.ReactModal__Overlay {
	z-index: 1;
}
