// Variable overrides
$primary: #20a8d8;
$secondary: #c8ced3;

$activeBorder: #8ad4ee;
$borderGrey: #e0e0e0;
$bgGrey: #f6f6f6;
$error: #f86c6b;

$sidebar-width: 230px;
